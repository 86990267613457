<template>
    <div>
        <b-card no-body class="mb-0 p-2">
            <LoadingComponent v-if="loading" />
            <TreeBrowser v-else :nodes="root" @onClick="nodeWasClicked()" />
        </b-card>
    </div>
</template>
  
<script>
import TreeBrowser from "../../components/TreeBrowser.vue";
import { BCard } from 'bootstrap-vue'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import router from "@/router";
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EventBus from '../../components/event-bus';
import LoadingComponent from "../../components/LoadingComponent.vue";

export default {
    components: {
        BCard,
        TreeBrowser,
        Prism,
        ToastificationContent,
        EventBus,
        LoadingComponent,
    },
    data() {
        return {
            loading: true,
            menuId: '',
            root: [
                {
                    menu_id: null,
                    label: "منو",
                    childs: [],
                }
            ],
        }
    },
    watch: {
        root: {
            deep: true,
            handler: (newRoot) => {
                // console.log(newRoot);
            },
        },
    },
    methods: {
        nodeWasClicked(node) {
            // alert(node.label);
        },

        getMenu() {
            this.menuId = router.currentRoute.params.id;
            this.root[0].childs = []
            this.loading = true
            axios
                .get(`/api/v1/admin/menu/${this.menuId}/view`)
                .then((response) => {
                    this.root[0].menu_id = response.data.data.id
                    this.root[0].childs = response.data.data.items
                    this.loading = false
                })
                .catch((error) => {
                    this.loading = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'خطا',
                            icon: 'XOctagonIcon',
                            text: 'مشکلی پیش آمده.',
                            variant: "danger",
                        },
                    })
                })
        },

    },
    mounted() {
        this.getMenu()

        EventBus.$on('menuItemChanged', this.getMenu);
    },
}
</script>