<template>
  <div>
    <div>
      <draggable class="dragArea" tag="div" :list="nodes" @end="onDragEnd">
        <div v-for="node in nodes" :key="node.label" :style="{ 'margin-right': `${depth * 10}px !important` }"
          class="node">
          <span v-if="node.childs && node.childs.length" class="type" @click="nodeClicked(node)">
            <feather-icon v-if="isExpanded(node)" icon="ChevronUpIcon" />
            <feather-icon v-else icon="ChevronDownIcon" />
          </span>

          <span class="type">
            <feather-icon v-if="node.childs && node.childs.length" icon="FolderIcon" />
            <feather-icon v-else icon="FileIcon" />
            <feather-icon v-if="node.label != 'منو'" @click="editFunction(node)" icon="Edit2Icon" />
            <feather-icon @click="plusFunction(node)" icon="PlusIcon" />
            <feather-icon v-if="node.label != 'منو'" @click="minusFunction(node)" icon="MinusIcon" />
          </span>
          <span :style="getStyle(node)">{{ node.label }}<span class="mb-0" dir="ltr">{{ node.url ? node.url + " :" : "" }}</span>
          </span>
          <TreeBrowser v-if="isExpanded(node) && node.childs" :nodes="node.childs" :depth="depth + 1"
            @onClick="(node) => $emit('onClick', node)" />
        </div>
      </draggable>
    </div>
    <!-- add to menu items modal -->
    <div id="modal-template" v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <b-card class="modal-container">
              <div class="modal-header px-0 pt-0">
                <h3 class="mb-0">
                  {{ isAddMenus ? 'افزودن به منو' : isEditMenus ? 'ویرایش آیتم' : 'حذف آیتم' }}
                </h3>
              </div>
              <div v-if="isAddMenus" class="modal-body px-0 pb-0">
                <b-form>
                  <b-form-group>
                    <label for="label">نام:</label>
                    <b-form-input id="label" v-model="formData.label" type="text" placeholder="نام" />
                  </b-form-group>
                  <b-form-group>
                    <label for="url">آدرس:</label>
                    <b-form-input dir="ltr" id="url" v-model="formData.url" type="text"
                      placeholder="/..." />
                  </b-form-group>
                </b-form>
              </div>
              <div v-if="isEditMenus" class="modal-body px-0 pb-0">
                <b-form>
                  <b-form-group>
                    <label for="label">نام:</label>
                    <b-form-input id="label" v-model="editFormData.label" type="text" placeholder="نام" />
                  </b-form-group>
                  <b-form-group>
                    <label for="url">آدرس:</label>
                    <b-form-input dir="ltr" id="url" v-model="editFormData.url" type="text"
                      placeholder="/..." />
                  </b-form-group>
                </b-form>
              </div>
              <div class="modal-footer" :class="isAddMenus ? '' : 'mt-1 border-0'">
                <b-button class="mt-1" variant="outline-primary" @click.prevent="closeModal()">
                  <span class="text-nowrap">بستن</span>
                </b-button>
                <b-button class="mt-1" v-if="isAddMenus" variant="primary" @click.prevent="addToMenus()">
                  <span class="text-nowrap">ثبت</span>
                </b-button>
                <b-button class="mt-1" v-if="isEditMenus" variant="primary" @click.prevent="editMenus()">
                  <span class="text-nowrap">ثبت</span>
                </b-button>
                <b-button class="mt-1" v-if="isRemoveMenus" variant="primary" @click.prevent="removeFromMenus()">
                  <span class="text-nowrap">حذف</span>
                </b-button>
              </div>
            </b-card>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
  
<script>
import draggable from "vuedraggable";
import { BCard, BRow, BCol, BButton, BForm, BFormGroup, BFormInput } from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EventBus from './event-bus';

export default {
  name: "TreeBrowser",
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ToastificationContent,
    draggable,
  },
  props: {
    nodes: Array,
    depth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      menu_id: '',
      nodeId: '',
      expanded: [],
      tempLabel: "label",
      tempUrl: "url",
      showModal: false,
      isAddMenus: false,
      isEditMenus: false,
      isRemoveMenus: false,
      reOrderTray: {
        items: []
      },
      formData: {
        menu_id: 1,
        label: "",
        badge: null,
        url: "",
        post_id: null,
        parent_id: null,
        order: 0,
        target: 0
      },
      editFormData: {
        menu_id: 1,
        label: "",
        badge: null,
        url: "",
        post_id: null,
        parent_id: null,
        order: 0,
        target: 0
      },
      editNodeId: null,
    };
  },
  methods: {
    isExpanded(node) {
      return this.expanded.indexOf(node) !== -1;
    },
    nodeClicked(node) {
      if (!this.isExpanded(node)) {
        this.expanded.push(node);
      } else {
        this.expanded.splice(this.expanded.indexOf(node));
      }
    },
    getStyle(node) {
      let color = "#3B4F93";
      if (!node.childs || !node.childs.length) {
        color = "#636363";
      }
      return {
        color,
      };
    },
    editFunction(node) {
      this.editNodeId = null
      this.editFormData.menu_id = null
      this.editFormData.label=node.label
      this.editFormData.url=node.url

      if (node.menu_id) {
        this.editFormData.menu_id = node.menu_id
      }

      if (node.id) {
        this.editNodeId = node.id
      } else {
        this.editNodeId = null
      }

      if (node.parent_id) {
        this.editFormData.parent_id = node.parent_id
      } else {
        this.editFormData.parent_id = null
      }

      if (node.order) {
        this.editFormData.order=node.order
      } else {
        this.editFormData.order=0
      }

      if (node.menu_id) {
        this.menu_id = node.menu_id
      }

      this.showModal = true;
      this.isEditMenus = true;
    },
    editMenus() {

      if (!this.editFormData.url.includes(`${process.env.VUE_APP_BASE_URL}/storage`)) {
        if (this.editFormData.url.includes(process.env.VUE_APP_BASE_URL.split('https://api.')[1])) {
          this.editFormData.url = this.editFormData.url.split(process.env.VUE_APP_BASE_URL.split('https://api.')[1])[1];
        }
      }

      axios.post(`/api/v1/admin/menu/items/${this.editNodeId}/update`, this.editFormData)
        .then((response) => {
          this.menuItemChanged()

          this.editFormData.label = ''
          this.editFormData.url = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'آیتم ویرایش شد',
              icon: 'XOctagonIcon',
              text: 'آیتم با موفقیت ویرایش شد',
              variant: "success",
            },
          })

        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'خطا',
              icon: 'XOctagonIcon',
              text: 'مشکلی پیش آمده.',
              variant: "danger",
            },
          })
        })

      this.showModal = false;
      this.isEditMenus = false;
    },
    plusFunction(node) {
      this.nodeId = null
      this.menu_id = null

      if (node.id) {
        this.nodeId = node.id
      } else {
        this.nodeId = null
      }

      if (node.menu_id) {
        this.menu_id = node.menu_id
      }
      this.showModal = true;
      this.isAddMenus = true;

    },
    addToMenus() {
      this.formData.menu_id = this.menu_id
      this.formData.parent_id = this.nodeId

      if (this.formData.url.includes(process.env.VUE_APP_BASE_URL.split('https://api.')[1])) {
        this.formData.url=this.formData.url.split(process.env.VUE_APP_BASE_URL.split('https://api.')[1])[1]
      }

      axios.post("/api/v1/admin/menu/items/create", this.formData)
        .then((response) => {
          this.menuItemChanged()

          this.formData.label = ''
          this.formData.url = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'آیتم جدید ساخته شد',
              icon: 'XOctagonIcon',
              text: 'آیتم جدید با موفقیت ساخته شد',
              variant: "success",
            },
          })

        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'خطا',
              icon: 'XOctagonIcon',
              text: 'مشکلی پیش آمده.',
              variant: "danger",
            },
          })
        })



      // this.expanded.push(node);

      this.showModal = false;
      this.isAddMenus = false;
    },
    minusFunction(node) {
      this.nodeId = null

      if (node.id) {
        this.nodeId = node.id
      } else {
        this.nodeId = null
      }

      this.showModal = true;
      this.isRemoveMenus = true;
    },
    removeFromMenus() {
      axios.post(`/api/v1/admin/menu/items/${this.nodeId}/delete`)
        .then((response) => {
          this.menuItemChanged()

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'آیتم مورد نظر حذف شد',
              icon: 'XOctagonIcon',
              text: 'آیتم مورد نظر با موفقیت حذف شد',
              variant: "info",
            },
          })
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'خطا',
              icon: 'XOctagonIcon',
              text: 'مشکلی پیش آمده.',
              variant: "danger",
            },
          })
        })



      // this.expanded.push(node);

      this.isRemoveMenus = false;
      this.showModal = false;
    },
    closeModal() {
      this.showModal = false;
      this.isRemoveMenus = false;
      this.isEditMenus = false;
      this.isAddMenus = false;
    },
    menuItemChanged() {
      // this.$emit('menuItemChanged')

      // event bus
      EventBus.$emit('menuItemChanged');
    },
    onDragEnd() {
      this.reOrderTray.items = []

      this.nodes.forEach(element => {
        this.reOrderTray.items.push({
          id: element.id,
          order: this.nodes.indexOf(element) + 1,
        })
      })

      axios.post('/api/v1/admin/menu/items/update-orders', this.reOrderTray).then((response) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'ترتیب آیتم ها تغییر کرد',
            icon: 'XOctagonIcon',
            text: 'ترتیب آیتم ها با موفقیت تغییر کرد',
            variant: "success",
          },
        })
      }).catch((error) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'خطا',
            icon: 'XOctagonIcon',
            text: 'مشکلی پیش آمده.',
            variant: "danger",
          },
        })
      })
    },
  },
};
</script>
<style>
.node {
  text-align: left;
  font-size: 18px;
}

.type {
  margin-right: 5px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 16;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #3B4F93;
}

.modal-default-button {
  float: right;
  border: none;
  border-radius: 2px;
  padding: 10px;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
  